import { configureStore } from '@reduxjs/toolkit';
import mainSlice from './main/mainSlice'; // main 슬라이스 reducer 가져오기
import mainTypeSlice from './main/mainTypeSlice'; // mainType 슬라이스 reducer 가져오기
import signupSlice from './user/signupSlice'; // signup 슬라이스 reducer 가져오기

//slice의 reducer 등록
const rootReducer = {
  main: mainSlice.reducer,
  mainType: mainTypeSlice.reducer,
  signup: signupSlice.reducer,
};

const store = configureStore({
  reducer: rootReducer
});

export default store;