import { createSlice } from '@reduxjs/toolkit';

const mainTypeSlice = createSlice({
    name: 'mainType',
    initialState: {value : true},
    reducers: {
        setMainType : (state, action) => {
            state.value = action.payload;
            localStorage.setItem("mainType", state.value);
            return state;
        }    
    },    
});

export default mainTypeSlice;
export const {setMainType} = mainTypeSlice.actions;