import React, {useEffect} from 'react';
import {useLocation, useNavigation} from 'react-router-dom';
import DynamicRoutes from './components/routes/dynamicRoutes';
import NavigationBar from './components/common/NavigationBar';
import { ToastContainer } from 'react-toastify';

function App() {

  useEffect(() => {
    if (window.Android) {
      localStorage.setItem("platform", "Android");
      console.log("Android");
    } else if (window.webkit && window.webkit.messageHandlers) {
      localStorage.setItem("platform", "iOS");
      console.log("iOS");
    } else {
      const os = detectOS();
      localStorage.setItem("platform", os);
    }
  }, []);

  function detectOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

  return (
    <div className="wrapper">
        <DynamicRoutes />
        <ToastContainer/>
        {/* <NavigationBar /> */}
    </div>
  );
}
// eslint-disable-next-line
export default App;

