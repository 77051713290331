// src/store/reducers/dataSlice.js
import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import http from '../../http-common';
import { backendUrl } from '../../assets/js/connectionState';

//비동기 회원정보 입력 처리
//register = build에서 사용
export const register = createAsyncThunk(    
  "signup/register",
  async (userData) => {
    console.log(userData);
    //서버로 통신
    // res = await http.post('http://43.200.13.61:9090/cpoint/app/mbl/user/joinAppUser', userData);
    const res = await http.post(backendUrl + 'user/joinAppUser', userData);
    // res = await signUpApi(userData);
    return res;
  }
);

const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    isRegistered : false,
    loading : false,
    error : null,
    notification : null,
    user : {
      userNm : '',
      userEmail : '',
      userMbtlNum : '',
      userBrbt : '',
      userCi : '',
      socialLgnTyp :'',
      socialLgnTkn:'',
      smsRcptnYn:'',
      emailRcptnYn:'',
      pushRcptnYn:'',
      marktRcptnYn:'',
      provisionType:'',
      provisionOrgan:'',
      depositor:'',
      accountNum:'',
      fcmToken:''
    },
    termsAgreeArr : []
  },
  reducers: {
    setTermsAgreeArr : (state, action) => {  //약관동의
      state.termsAgreeArr = action.payload;   //...(spred연산자)을 쓰지 않고 내부적으로 이를 처리하도록 되어있음.
      return state; //생략 가능하지만, 가독성을 위해서 입력.
    }
  },
  extraReducers : (builder) => {
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(register.fulfilled, (state,action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

//reducer 함수 추출
export default signupSlice;

//액션 생성자 함수들을 추출
export const { setTermsAgreeArr } = signupSlice.actions;
