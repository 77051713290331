// src/store/reducers/dataSlice.js
import { PayloadAction, createSlice, combineReducers } from '@reduxjs/toolkit';
import axios from 'axios';
/*
export const fetchData = createAsyncThunk(
  'main/fetchData', //비동기 호출 name : main밑에 extraReducers 의 fetchData 
  async () => {
    const response = await axios.get('https://jsonplaceholder.typicode.com/todos/1');
    return response.main;
  }
);
*/

const mainSlice = createSlice({
  name: 'main',
  initialState: {value : 0},
  reducers: {
    //test
    up : (state, action) => {
      state.value = state.value + action.payload;
      return state;
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchData.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(fetchData.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.data = action.payload;
  //       state.error = null;
  //     })
  //     .addCase(fetchData.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

// const mainStore = combineReducers({
//   mainStates: mainSlice.reducer
// });

export default mainSlice;
export const {up} = mainSlice.actions;