import React from 'react';
import { styled } from 'styled-components';

const NotFoundDiv = styled.div`
    text-align: center;
`;

export default function NotFound() {
    return (
        <div className='content-wrap'>
            <NotFoundDiv className='content'>
                <h1>페이지를 찾을 수 없습니다.</h1>
            </NotFoundDiv>
        </div>
    )
}