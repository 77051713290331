import React, { Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NotFound from "../common/NotFound";
import Loading from "../common/Loading.js";
//import Home from "../../pages/Home";

const Home = lazy(() => import('../../pages/Home'));
const Main = lazy(() => import('../../pages/main/index.js'));
const Login = lazy(() => import('../../pages/login/index.js'));
const UserAuth = lazy(() => import('../../pages/user/signup/green/userAuth.js'));
const UserInfo = lazy(() => import('../../pages/user/signup/green/userInfo.js'));
const TermsAgree = lazy(() => import('../../pages/user/signup/green/termsAgree.js'));
const FileUploadExample = lazy(() => import('../../pages/fileuploadExample/index.js'));
const SignupComplete = lazy(() => import('../../pages/user/signup/green/signupComplete.js'));
const EasypassRegist = lazy(() => import('../../pages/user/signup/green/easypassRegist.js'));
const SimpleNum = lazy(() => import('../../pages/user/signup/green/simpleNum.js'));
const KbCheck = lazy(() => import('../../pages/user/signup/green/kbCheck.js'))
const KbComplete = lazy(() => import('../../pages/user/signup/green/kbComplete.js'))

const AppContent = lazy(() => import('../../pages/content/index.js'));

const MyTest = lazy(() => import('../../pages/mypage/index.js'));

//소셜로그인
const RedirectNaver = lazy(() => import('../../components/login/RedirectNaver.js'));
const RedirectKakao = lazy(() => import('../../components/login/RedirectKakao.js'));
const RedirectNaverRegist = lazy(() => import('../../components/login/RedirectNaverRegist.js'));
const RedirectKakaoRegist = lazy(() => import('../../components/login/RedirectKakaoRegist.js'));

const NoticeIndex = lazy(() => import('../../pages/comm/notice/index.js'));
const NoticeView = lazy(() => import('../../pages/comm/notice/view.js'));
const FaqIndex = lazy(() => import('../../pages/comm/faq/index.js'));
const TellnumInfo = lazy(() => import('../../pages/alarm/tellnum/index.js'));

const EcoPrdctIndex = lazy(() => import('../../pages/promotion/ecoPrdct/index.js'));
const EcoPrdctView = lazy(() => import('../../pages/promotion/ecoPrdct/view.js'));
const ArchiveIndex = lazy(() => import('../../pages/promotion/archive/index.js'));
const ArchiveView = lazy(() => import('../../pages/promotion/archive/view.js'));

const EventIndex = lazy(() => import('../../pages/events/event/index.js'));
const EventView = lazy(() => import('../../pages/events/event/view.js'));

const MyPageMain = lazy(() => import('../../pages/mypage/layout.js'));
const PartnerMyPageMain = lazy(() => import('../../pages/mypage/layoutPartner.js'));
const MyInfo = lazy(() => import('../../pages/mypage/user/myInfo.js'));
const MyAuth = lazy(() => import('../../pages/mypage/user/myAuth.js'));
const RegisterDoc = lazy(() => import('../../pages/mypage/user/registerDoc.js'));
const UserExit = lazy(() => import('../../pages/mypage/user/userExit.js'));
const PushIndex = lazy(() => import('../../pages/mypage/push/index.js'));
const SetupInfo = lazy(() => import('../../pages/mypage/setup/setupInfo.js'));
const SetupInfoPartner = lazy(() => import('../../pages/mypage/setup/setupInfoPartner.js'));
const SetupSimplenum = lazy(() => import('../../pages/mypage/setup/setupSimplenum.js'));

const NearShop = lazy(() => import('../../pages/mypage/shop/nearShop.js'));
const MemberShip = lazy(() => import('../../pages/mypage/mbership/memberShip.js'));
const MyPointList = lazy(() => import('../../pages/mypage/point/index.js'));
const MyPointView = lazy(() => import('../../pages/mypage/point/view.js'));
const MyPointList2 = lazy(() => import('../../pages/mypage/point/index2.js'));
const MyPointList3 = lazy(() => import('../../pages/mypage/point/index3.js'));

// 에너지 가입
const EnergySelectMember = lazy(() => import('../../pages/user/signup/energy/energySelectMember.js'));
const EnergySelectType = lazy(() => import('../../pages/user/signup/energy/energySelectType.js'));
const EnergyTermsAgree = lazy(() => import('../../pages/user/signup/energy/energyTermsAgree.js'));
const EnergyAuthentication = lazy(() => import('../../pages/user/signup/energy/energyAuthentication.js'));
const EnergyUserInfo = lazy(() => import('../../pages/user/signup/energy/energyUserInfo.js'));

const CarInstruction = lazy(() => import('../../pages/user/signup/car/carInstruction.js'));
const CarSelectMember = lazy(() => import('../../pages/user/signup/car/carSelectMember.js'));
const CarTermsAgree = lazy(() => import('../../pages/user/signup/car/carTermsAgree.js'));
const CarUserInfo = lazy(() => import('../../pages/user/signup/car/carUserInfo.js'));
const CarUserInfo2 = lazy(() => import('../../pages/user/signup/car/carUserInfo2.js'));
const CarUserInfo3 = lazy(() => import('../../pages/user/signup/car/carUserInfo3.js'));


const PartnerAuth = lazy(() => import('../../pages/user/signup/partner/partnerAuth.js'));
const PartnerUserInfo = lazy(() => import('../../pages/user/signup/partner/partnerUserInfo.js'));
const ScannerInfo = lazy(() => import('../../pages/user/signup/partner/scannerInfo.js'));


// QR코드
const QrCode = lazy(() => import('../../components/common/QrCode.js'));


// 통합검색
const SearchIndex = lazy(() => import('../../pages/search/index.js'));

//탈퇴완료
const WithdrawComplete = lazy(() => import('../../pages/user/signup/green/withdrawComplete.js'));


export default function dynamicRoutes() {

  return (
    // <Suspense fallback={<Loading/>}>  로딩
    <Suspense fallback={<Loading/>}>
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/main" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup/green/termsAgree" element={<TermsAgree />} />
            <Route path="/signup/green/userAuth" element={<UserAuth />} />
            <Route path="/signup/green/userInfo" element={<UserInfo />} />
            <Route path="/signup/green/signupComplete" element={<SignupComplete />} /> 
            <Route path="/signup/green/easypassRegist" element={<EasypassRegist />} /> 
            <Route path="/signup/green/simpleNum" element={<SimpleNum />} />
            <Route path="/signup/green/kbCheck" element={<KbCheck />} />
            <Route path="/signup/green/kbComplete" element={<KbComplete />} />

            {/* 탄소포인트란 */}
            <Route path="/content/appContent" element={<AppContent />} /> 

            {/* 에너지가입 */}
            <Route path="/signup/energy/energySelectMember" element={<EnergySelectMember />} /> 
            <Route path="/signup/energy/energySelectType" element={<EnergySelectType />} /> 
            <Route path="/signup/energy/energyTermsAgree" element={<EnergyTermsAgree />} /> 
            <Route path="/signup/energy/energyAuthentication" element={<EnergyAuthentication />} /> 
            <Route path="/signup/energy/energyUserInfo" element={<EnergyUserInfo />} />

            {/* 자동차가입 */}
            <Route path="/signup/car/carInstruction" element={<CarInstruction />} /> 
            <Route path="/signup/car/carSelectMember" element={<CarSelectMember />} /> 
            <Route path="/signup/car/carTermsAgree" element={<CarTermsAgree />} /> 
            <Route path="/signup/car/carUserInfo" element={<CarUserInfo />} /> 
            <Route path="/signup/car/carUserInfo2" element={<CarUserInfo2 />} /> 
            <Route path="/signup/car/carUserInfo3" element={<CarUserInfo3 />} />

            {/* 그린파트너  */}
            <Route path="/signup/partner/partnerAuth/:type" element={<PartnerAuth />} /> 
            <Route path="/signup/partner/partnerUserInfo" element={<PartnerUserInfo />} /> 
            <Route path="/signup/partner/scannerInfo" element={<ScannerInfo />} /> 

            {/* 알림 소통 */}
            {/* 공시사항 */}
            <Route path="/comm/notice/list" element={<NoticeIndex />} /> 
            <Route path="/comm/notice/view/:bbsId" element={<NoticeView />} /> 
            {/* FaQ */}
            <Route path="/comm/faq/list" element={<FaqIndex />} /> 
            {/* 에너지문의 전황 */}
            <Route path="/alarm/tellnum/list" element={<TellnumInfo />} /> 

            <Route path="/mypage/mytest" element={<MyTest />} />

            {/* 소셜로그인 */}
            <Route path="/login/naver" element={<RedirectNaver />} /> 
            <Route path="/login/kakao" element={<RedirectKakao />} /> 

            <Route path="/regist/naver" element={<RedirectNaverRegist />} /> 
            <Route path="/regist/kakao" element={<RedirectKakaoRegist />} /> 

            {/* 홍보 자료 */}
            {/* 친환경 제품 */}
            <Route path="/promotion/ecoPrdct/list" element={<EcoPrdctIndex />} />
            <Route path="/promotion/ecoPrdct/view/:bbsId" element={<EcoPrdctView />} />
            <Route path="/promotion/archive/list" element={<ArchiveIndex />} />
            <Route path="/promotion/archive/view/:bbsId" element={<ArchiveView />} />

            {/* 이벤트 */}
            {/* 이벤트 */}
            <Route path="/events/event/list" element={<EventIndex />} /> 
            <Route path="/events/event/view/:eventId/:activeTab" element={<EventView />} />  

            {/* 마이페이지 설정 */}
            <Route path="/mypage/main" element={<MyPageMain />} />
            <Route path="/mypage/partnerMain" element={<PartnerMyPageMain />} />
            {/* 정보수정 */}
            <Route path="/mypage/user/myInfo" element={<MyInfo />} /> 
            <Route path="/mypage/user/myAuth/:type" element={<MyAuth />} /> 
            <Route path="/mypage/user/registerDoc" element={<RegisterDoc />} /> 
            {/* 탈퇴 */}
            <Route path="/mypage/user/userExit" element={<UserExit />} /> 
            {/* 알림함 */}
            <Route path="/mypage/push/list" element={<PushIndex />} /> 
            {/* 설정 */}
            <Route path="/mypage/setup/setupIndex" element={<SetupInfo />} /> 
            <Route path="/mypage/setup/setupIndexPartner" element={<SetupInfoPartner />} /> 
            <Route path="/mypage/setup/setupSimplenum" element={<SetupSimplenum />} /> 

            {/* 근처 포인트 매장 */}
            <Route path="/mypage/shop/nearShop" element={<NearShop />} /> 
            <Route path="/mypage/mbership/memberShip" element={<MemberShip />} /> 
            {/* 제도 별 실적 조회 */}
            <Route path="/mypage/point/myPointList" element={<MyPointList />} /> 
            <Route path="/mypage/point/myPointView/:type" element={<MyPointView />} /> 
            <Route path="/mypage/point/myPointList2" element={<MyPointList2 />} /> 
            <Route path="/mypage/point/myPointList3/:type" element={<MyPointList3 />} /> 


            <Route path="/qrcode" element={<QrCode />} /> 
            <Route path="/search/searchIndex" element={<SearchIndex />} /> 

            <Route path="/green/withdrawComplete" element={<WithdrawComplete />} /> 

            {/* <Route path="/uploadtest" element={<FileUploadExample />} />테스트페이지 */}
            <Route element={<NotFound />} />
        </Routes>
    </Suspense>
  );
}