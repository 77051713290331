import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../../assets/css/style.css';

export default function NavigationBar(){
    // 로그인 객체
    const loginUser = JSON.parse(localStorage.getItem("loginUser"));
    // QR팝업
    const [show, setShow] = useState(false);
    // 로그인 팝업
    const [loginShow, setLoginShow] = useState(false);
    
    /*
        QR코드 이동 팝업
        동의 후 QR코드 이동    
    */
    const handleButtonClick = (e) => {
        setShow(true);
        // window.location.href = "/qrcode";
    }
    // QR코드 팝업 닫기
    const handleClose = () => setShow(false);
    
    /*
        로그인 체크 
        로그인 되있을 경우 Link to 이동
        로그인 안됬을 경우 로그인 요구 팝업
        팝업에서 이동 시 로그인으로 이동
    */
    const loginChecker = (e) => {
        if(loginUser == null) {
            setLoginShow(true);
            e.preventDefault();
        }
    }
    // 로그인 팝업 닫기
    const loginCheckerClose = () => setLoginShow(false);


    return(
        <>
            {/* { 
                // 로그인 유저만 QR코드 화면 확인 가능
                loginUser != null 
                ? 
                <div className="myqr">
                    <button type="button" onClick={(e) => handleButtonClick(e)}>
                        <img src="/img/menu-myqr.svg" alt="qr" /><span>나의 QR</span>
                    </button>
                </div>
                :
                <div className="myqr">
                    <button type="button" onClick={(e) => loginChecker(e)}>
                        <img src="/img/menu-myqr.svg" alt="qr" /><span>나의 QR</span>
                    </button>
                </div>
            } */}
            <div className="BottomMenu">
                <Link to="/mypage/point/myPointList2" className="item" onClick={(e) => { loginChecker(e) }}>
                    <div className="col nav-point">
                        {/* <strong>포인트</strong> */}
                    </div>
                </Link>
                <Link to="/main" className="item">
                    <div className="col nav-home">
                        {/* <strong>홈</strong> */}
                    </div>
                </Link>
                {
                    //20240619 수정
                    // 로그인 유저만 QR코드 화면 확인 가능
                    loginUser != null ? (
                        <button
                        type="button"
                        className="my-qr"
                        onClick={(e) => handleButtonClick(e)}
                        >
                        <img src="/img/menu-myqr.svg" alt="qr" />
                        </button>
                    ) : (
                        <button
                        type="button"
                        className="my-qr"
                        onClick={(e) => loginChecker(e)}
                        >
                        <img src="/img/menu-myqr.svg" alt="qr" />
                        </button>
                    )
                }
                <Link to="/search/searchIndex" className="item">
                    <div className="col nav-search">
                        {/* <strong>검색</strong> */}
                    </div>
                </Link>
                <Link to="/mypage/main" className="item" onClick={(e) => { loginChecker(e) }}>
                    <div className="col nav-mypage">
                        {/* <strong>마이</strong> */}
                    </div>
                </Link>
            </div>

            {/* QR코드 팝업 */}
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="">위치정보제공동의</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="cont" style={{ paddingBottom:"80px"}}>탄소중립포인트 APP 이 회원님의 위치에 접근하도록 허용하시겠습니까?</div>
                    {/* <div className="sub">회원님의 위치정보는 주변매장 찾기 사용실적 정산 목적으로 사용됩니다.</div> */}
                    <div className="form-button-group">
                        <Link to="/qrcode" className="btn btn-primary btn-block btn-lg">동의</Link>
                    </div>  
                </Offcanvas.Body>
            </Offcanvas>

            {/* 로그인 팝업 */}
            <Offcanvas show={loginShow} onHide={loginCheckerClose} placement="bottom">
                <Offcanvas.Header closeButton className="flex-end">
                    <Offcanvas.Title className="none">로그인 필요</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="cont" style={{ paddingBottom:"80px"}}>
                        <p className="login-error"><span>로그인이 필요합니다.</span></p>
                    </div>
                    <div className="form-button-group">
                        <Link to="/login" className="btn btn-primary btn-block btn-lg">로그인 화면으로 이동</Link>
                    </div>  
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}